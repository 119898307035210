import React from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';

import { Hreflangs } from 'ui/components/Hreflangs';
import { removeLeadingSlash } from 'utils/removeLeadingSlash';
import { useMainNavContext } from 'hooks/useMainNav';
import { REGION_INFO } from 'utils/regionBasedConstants';
import { Maybe } from '__generated__/graphql';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { getMappedCountryCode, removeLocalePrefix } from 'utils/locale';
import { parseHtml } from 'utils/html';
export const PageHead: React.FC<{
  title: Maybe<string> | undefined;
  description?: Maybe<string> | undefined;
  openGraphDescription?: Maybe<string> | undefined;
  openGraphImageUrl?: Maybe<string> | undefined;
  openGraphTitle?: Maybe<string> | undefined;
  openGraphType?: Maybe<string> | undefined;
  openGraphVideoUrl?: Maybe<string> | undefined;
  twitterDescription?: Maybe<string> | undefined;
  twitterImageUrl?: Maybe<string> | undefined;
  twitterTitle?: Maybe<string> | undefined;
  noIndex?: Maybe<boolean> | undefined;
  noIndexFollow?: Maybe<boolean> | undefined;
  noHreflangs?: Maybe<boolean> | undefined;
  canonical?: Maybe<string> | undefined;
  hreflangs?: Record<string, Maybe<string>>;
}> = props => {
  const { asPath } = useRouter();
  const { host, locale, countryCode, localizeUrlPath } = useSiteConfig();
  const { mobileNavLinks } = useMainNavContext();

  const hostIndexBlackList = [
    'web.development.cybercat.',
    'web.staging.cybercat.',
    '.production.cybercat.',
    'web.nitro.development.xetic.puma.com',
    'web.nitro.staging.xetic.puma.com',
    'pre-web.nitro.development.xetic.puma.com',
    '.nitro.production.xetic.puma.com',
  ];

  const isIndexableHost = hostIndexBlackList.some(baseUrl =>
    host.includes(baseUrl)
  );

  const path = removeLocalePrefix(asPath, locale);
  const mobileOnlyPaths = mobileNavLinks?.children
    ? Object.values(mobileNavLinks.children).map(link =>
        removeLeadingSlash(link.link.href)
      )
    : [];
  const isPathMobileOnly = mobileOnlyPaths.includes(removeLeadingSlash(path));
  const index =
    props.noIndex || isIndexableHost || isPathMobileOnly ? 'noindex' : 'index';

  const follow =
    (props.noIndex || isIndexableHost) && !props.noIndexFollow
      ? 'nofollow'
      : 'follow';
  const robotsContent = `${index}, ${follow}`;

  const sanitizedDescription = parseHtml(props.description!);
  const mappedCountryCode =
    getMappedCountryCode(countryCode) || countryCode.toLowerCase();
  const countryConstants = REGION_INFO[mappedCountryCode];

  const sanitizedOgDescription = props.openGraphDescription
    ? parseHtml(props.openGraphDescription)?.textContent
    : '';
  const sanitizedTwitterDescription = props.twitterDescription
    ? parseHtml(props.twitterDescription)?.textContent
    : '';
  const getHost = host => {
    const hostPrefix = host.startsWith('localhost') ? 'http://' : 'https://';
    return `${hostPrefix}${host}`;
  };
  const pumaLogo = `${getHost(host)}/assets/puma-logo-1200x630.jpg`;

  const ogType = props.openGraphType || 'website';
  const ogImage = props.openGraphImageUrl || pumaLogo;
  const twitterImage = props.twitterImageUrl || pumaLogo;

  const description = sanitizedDescription?.textContent || '';
  const pathnameNoLocale = (
    props.canonical || removeLocalePrefix(asPath, locale)
  )?.split('?')?.[0];
  const canonicalPathname = localizeUrlPath(pathnameNoLocale);

  return (
    <>
      <Hreflangs
        noHreflangs={props.noHreflangs}
        hreflangs={props.hreflangs}
        path={pathnameNoLocale}
      />
      <Head>
        <link rel="canonical" href={`https://${host}${canonicalPathname}`} />
        <meta
          property="og:url"
          content={`https://${host}${canonicalPathname}`}
        />
        {countryConstants?.name && (
          <meta
            property="og:site_name"
            content={`PUMA ${countryConstants.name}`}
          />
        )}
        {props.openGraphVideoUrl && (
          <meta property="og:video" content={props.openGraphVideoUrl} />
        )}
        <meta property="og:type" content={ogType} />
        <meta property="og:image" content={ogImage} />
        <meta property="twitter:image" content={twitterImage} />
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:site" content="@puma" />
        {props.title && (
          <>
            <title>{props.title}</title>
            <meta
              property="twitter:title"
              content={props.twitterTitle || props.title}
            />
            <meta
              property="og:title"
              content={props.openGraphTitle || props.title}
            />
          </>
        )}
        {props.description && (
          <>
            <meta name="description" content={description} />
            <meta
              property="og:description"
              content={sanitizedOgDescription || description}
            />
            <meta
              property="twitter:description"
              content={sanitizedTwitterDescription || description}
            />
          </>
        )}

        <meta name="robots" content={robotsContent} />
        {props.children}
      </Head>
    </>
  );
};
