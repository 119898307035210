import Head from 'next/head';

import { getUrlFromLocale } from 'utils/locale';
import { useSiteConfig } from 'hooks/useSiteConfig';
import { Maybe } from '__generated__/graphql';
import { availablePages, hardcodedHreflangs } from 'utils/hardcodedHreflangs';

const localizeUrl = (originalPath: string, locale: string, host: string) => {
  const path = originalPath.replace(/^\//, ''); // Remove leading slash
  const localizedPath = `${getUrlFromLocale(locale)}/${path}`;
  const hostPrefix = host.startsWith('localhost') ? 'http://' : 'https://';
  const absoluteHost = `${hostPrefix}${host}`;

  return `${absoluteHost}${localizedPath}`;
};

export const Hreflangs = ({
  noHreflangs,
  hreflangs,
  path,
  id,
}: {
  noHreflangs?: Maybe<boolean> | undefined;
  hreflangs?: Record<string, Maybe<string>>;
  path?: string;
  id?: string;
}) => {
  const { host, supportedLocales, country } = useSiteConfig();

  if (id && availablePages.includes(id)) {
    return (
      <>
        {/* This Head has to be here, if it <Head><Hreflangs /></Head> is used then some hreflangs get lost on CSR */}
        <Head>
          {hardcodedHreflangs[id].map(({ hreflang, href }, index) => (
            <link key={index} rel="alternate" hrefLang={hreflang} href={href} />
          ))}
        </Head>
      </>
    );
  }

  if (
    hreflangs &&
    Object.keys(hreflangs).length > 1 &&
    supportedLocales?.length > 1
  ) {
    return (
      <Head>
        {Object.entries(hreflangs).map(([key, value]) => {
          return (
            supportedLocales.includes(`${key}-${country}`.toLowerCase()) && (
              <link
                key={key}
                rel="alternate"
                hrefLang={`${key}-${country}`}
                href={localizeUrl(value || '', `${key}-${country}`, host)}
              />
            )
          );
        })}
      </Head>
    );
  }

  return (
    <Head>
      {supportedLocales?.length > 1 &&
        !noHreflangs &&
        path &&
        supportedLocales?.map((locale: string) => {
          const [lang, country] = locale.split('-');
          const hrefLang = `${lang}-${country.toUpperCase()}`;
          const localeUrl = getUrlFromLocale(locale);
          return (
            <link
              key={hrefLang}
              rel="alternate"
              hrefLang={hrefLang}
              href={`https://${country}.puma.com${localeUrl}${path}`}
            />
          );
        })}
    </Head>
  );
};
